* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.invoice-wrapper {
  height: 100vh;
  background-color: rgb(245, 241, 241);

  /* height: auto */
}

.invoice-container {
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 241, 241);
}
#image {
  background-color: white;
  background-image: url('../../../public/TJ_LOGO_en.png');
  background-repeat: no-repeat;
  background-position: center;
  max-height: auto;
  width: 80%;
  background-size: 100% auto;
  zoom: 68%;
}
.inner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* #background {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  background: white;
  height: max-content;
} */

#content {
  position: absolute;
  z-index: 1;
  width: 620px;
  margin-top: 20px;
}

#bg-text {
  transform: rotate(330deg);
  -webkit-transform: rotate(330deg);
  opacity: 0.15;
}

.colorSubHeading1 {
  color: #8d9299;
  font-size: 17px;
  font-weight: 400;
  line-height: 16.41px;
}
.colorSubHeading {
  color: #8d9299;
  font-size: 15px;
  font-weight: 400;
  line-height: 16.41px;
}

.colorSubHeadingRight {
  font-weight: 600;
  line-height: 16.41px;
  font-size: 15px;
  color: #02172c;
}

@media screen and (min-width: 769px) {
  .invoice-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 241, 241);
  }
  }