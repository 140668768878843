.notificationCount {
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: red;
  top: -7px;
  left: 10px;

  ackground-color: red;
  color: white;
  text-align: center;
  border-radius: 50%;
}
