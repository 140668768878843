#cta-btn {
  margin-top: 20px;
  background-color: #5569ff;
  color: white;
}
#back-btn {
  margin-top: 20px;
  background-color: gray;
  color: white;
}
#cta-btn-disabled {
  margin-top: 20px;
  background-color: #d7d7d7;
  color: white;
}
#success {
  margin-top: 20px;
  background-color: green;

  color: white;
}
#reject {
  margin-top: 20px;

  background-color: #e30000;
  color: white;
}
