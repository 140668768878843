.slide {
  animation: slide-in-anim 0.5s ease-out forwards;
}
@keyframes slide-in-anim {
  20% {
    width: 100px;
  }

  100% {
    width: 400px;
  }
}

.JustDetailEnquiry {
  background-color: rgba(22, 22, 22, 0.2) !important;
}




