#cta-btn {
  margin-top: 20px;
  background-color: #5569ff;
  color: white;
}
#cta-btn-disabled {
  margin-top: 20px;
  background-color: #d7d7d7;
  color: white;
}
#success {
  margin-top: 20px;
  background-color: green;

  color: white;
}
#reject {
  margin-top: 20px;

  background-color: #e30000;
  color: white;
}
#card-clicked {
  box-shadow: 1px 1px 2px 3px #5569ff;
}
#approve-parking-card:hover {
  scale: 1.02;
  cursor: pointer;
  box-shadow: 1px 1px 2px 3px gray;
  transition: 0.2s;
}
.leaflet-container {
  width: inherit;
  height: 60vh;
}

/*  css for request document form */
.custom-grid-container {
  margin: 16px;
}

.custom-grid-item {
  padding: 8px;
  border: 1px solid #d7d2d2;
}
#closeBtn {
  display: flex;
  justify-content: flex-end;
}
