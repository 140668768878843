    
  
  .fileUploadGrid {
    width: 100%;
  }
  
  .view {
    width: 15%;
  }
  .fileUploadNothidden{
    width: 85%;

  }
 
  .notview {
    display: none;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .fileUploadGrid,
    .viewGrid {
      width: 100%;
    }
  }
  