.calender-input-container .rs-picker-toggle-textbox {
  padding: 2.5px 4px 2.5px 6px;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: calc(15px / 2);

  outline: 1px solid slategrey;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #696969;
}
.calender-input-container .rs-picker-toggle-placeholder {
  color: #b3b3b3;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
}
#create-lead-btn:hover {
  background-color: green;
}
.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-end {
  z-index: 1300 !important;
}
