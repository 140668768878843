input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

input[type='file']::file-selector-button:hover {
  background: #0d45a5;
}
input[type='file'] {
  width: 250px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

.InputBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container-bulk.table.MuiTable-root thead th {
  white-space: nowrap;
}

.table-container-bulk.css-132f3yf-MuiTableRow-root td {
  white-space: nowrap;
}

.no-wrap-table {
  white-space: nowrap;
}
