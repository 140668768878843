.rs-container,
.rs-pending {
  position: relative;
  text-align: left;
  padding-left: 22px;
}
.rs-container-tail,
.rs-pending-tail {
  background-color: RGB(83 102 249);
  bottom: 0;
  position: absolute;
  top: 0;
  left: 4px;
  width: 2px;
}
.rs-pending-tail {
  margin-top: 5px;
}
.rs-container-dot,
.rs-pending-dot {
  position: absolute;
  top: 5px;
  left: 0;
}
.rs-container-dot::before,
.rs-pending-dot::before {
  background-color: RGB(83 102 249);
  border-radius: 50%;
  content: '';
  display: block;
  height: 10px;
  width: 10px;
}
.rs-pending-dot::before {
  background-color: grey;
}
.rs-container-content,
.rs-pending-content {
  padding-bottom: 18px;
}
