.lead-detail-main-container {
  /* margin-left: 60px; */
  padding: 20px;
  overflow: auto !important;
}
.dark-clr {
  background: rgb(17, 25, 42);
}
.lead-detail-main-container p {
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  color: #ffff;
  margin-left: 10px;
}
.heading-h5 {
  color: rgb(17, 25, 42);
}
/*
  .lead-detail-btn-container {
    margin-top:30px;
    display: flex;
    justify-content: flex-end;
  }
  
  .lead-detail-btn-container button {
    width: 159px;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    border: none;
    background: #006db7;
    color: #ffff;
    font-size: 14px;
    font-weight: 500;
  }*/

.lead-detail-btn-container button {
  width: 159px;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  background: rgb(17, 25, 42);
  color: #ffff;
  font-size: 14px;
  font-weight: 500;
}

.lead-detail-table-container {
  margin-top: 30px;
}

.lead-detail-table-heading {
  background: rgb(17, 25, 42);
  color: #ffff;
  font-size: 20px;
  font-weight: 500;
  padding: 5px;
}
.lead-detail-table {
  border: 1px solid #a7a7a7;
  padding: 0px;
}
.lead-detail-table-content {
  border-bottom: 1px solid #a7a7a7;
  margin: 0px;
  padding: 8px;
}

.text-detail-form {
  overflow: hidden;
}
.lead-detail-table-heading-content {
  background: rgb(17, 25, 42);
  color: #ffff;
  padding: 5px 0px;
}
.lead-detail-field-name {
  flex: 2;
  padding-left: 21px;
  font-size: 20px;
  font-weight: 700;
}

.lead-detail-field-value {
  flex: 3;
  font-size: 20px;
  font-weight: 700;
  padding-left: 21px;
}
