.rs-task-pending-time,
.rs-task-completed-time {
  font-weight: 600;
}

.rs-task-pending,
.rs-task-completed {
  display: flex;
  padding-left: 0;
  position: relative;
  text-align: left;
}
.rs-task-pending-tail,
.rs-task-completed-tail {
  left: calc(50% - 1px);
  background-color: #d7d7d7;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 2px;
}
.rs-task-pending-tail {
  margin-top: 5px;
}
.rs-task-pending-dot,
.rs-task-completed-dot {
  position: absolute;
  top: 5px;
  left: calc(50% - 5px);
}
.rs-task-pending-dot::before,
.rs-task-completed-dot::before {
  background-color: #d7d7d7;
  border-radius: 50%;
  content: '';
  display: block;
  height: 10px;
  width: 10px;
}
.rs-task-pending-time,
.rs-task-completed-time {
  padding-right: 17px;
  text-align: right;
  flex: 1 0 50%;
}
.rs-task-pending-content,
.rs-task-completed-content {
  padding-left: 17px;
  text-align: left;
  flex: 1 0 50%;
}

.rs-task-completed-tail,
.rs-task-completed-dot::before {
  background-color: #5569ff;
}
