#custom-underline {
  position: relative;
  text-decoration: none;
}

#custom-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%; /* Adjust the value to control the underline length */
  height: 2px;
  background-color: blue; /* Adjust the color as needed */
}
