@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(2deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
#inventory-container {
  animation: rotateMenu 470ms ease-in-out forwards;
  transform-origin: top center;
}
#inventory-tab {
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}
#inventory-tab:hover {
  background-color: rgba(255, 255, 255, 0.06);
}
